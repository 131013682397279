import { useWindowSize, debouncedWatch } from "@vueuse/core";

export function useScrollTriggerAnimation() {
  const { width } = useWindowSize();

  function textSplitter(splitted, type) {
    splitted.revert();
    splitted.split({
      type: type,
      charsClass: "splitext-char overflow-hidden",
      wordsClass: "splitext-word overflow-hidden",
      linesClass: "splitext-line overflow-hidden",
    });
  }

  const parallax = (target, trigger, options) => {
    const INTENSITY = { strong: 60, normal: 30, weak: 15 };

    if (!options.intensity) options.intensity = "normal";
    if (!options.onEnter) options.onEnter = () => {};
    if (!options.onLeave) options.onLeave = () => {};
    if (!options.onEnterBack) options.onEnterBack = () => {};
    if (!options.onLeaveBack) options.onLeaveBack = () => {};
    if (!options.onToggle) options.onToggle = () => {};
    if (!options.onUpdate) options.onUpdate = () => {};
    if (!options.onScrubComplete) options.onScrubComplete = () => {};
    if (!options.onRefresh) options.onRefresh = () => {};

    requestAnimationFrame(() => {
      gsap.from(target, {
        scrollTrigger: {
          trigger,
          start: "top bottom",
          end: "bottom bottom",
          scrub: 1,
          onEnter: options.onEnter,
          onLeave: options.onLeave,
          onEnterBack: options.onEnterBack,
          onLeaveBack: options.onLeaveBack,
          onToggle: options.onToggle,
          onUpdate: options.onUpdate,
          onScrubComplete: options.onScrubComplete,
          onRefresh: options.onRefresh,
        },
        yPercent: INTENSITY[options.intensity],
        force3D: "auto",
        ease: "sine.out",
      });
    });
  };

  const reveal = (target, trigger, origin, options) => {
    const INTENSITY = {
      xy: { strong: 56, normal: 32, weak: 16 },
      scaleUp: { strong: 0, normal: 0.5, weak: 0.85 },
      scaleDown: { strong: 1.5, normal: 1.25, weak: 1.1 },
    };

    const DURATION = { long: 1.2, normal: 0.75, quick: 0.3 };
    const STAGGER = { wide: 0.3, normal: 0.15, short: 0.05, none: 0 };

    if (!options.fade) options.fade = false;
    if (!options.intensity) options.intensity = "normal";
    if (!options.duration) options.duration = "normal";
    if (!options.useChildren) options.useChildren = false;
    if (!options.stagger) options.stagger = "none";
    if (!options.onEnter) options.onEnter = () => {};
    if (!options.onLeave) options.onLeave = () => {};
    if (!options.onEnterBack) options.onEnterBack = () => {};
    if (!options.onLeaveBack) options.onLeaveBack = () => {};
    if (!options.onToggle) options.onToggle = () => {};
    if (!options.onUpdate) options.onUpdate = () => {};
    if (!options.onRefresh) options.onRefresh = () => {};

    let params = {};
    switch (origin) {
      case "scale-up":
        params.scale = INTENSITY.scaleUp[options.intensity];
        break;

      case "scale-down":
        params.scale = INTENSITY.scaleDown[options.intensity];
        break;

      case "top":
        params.y = -INTENSITY.xy[options.intensity];
        break;

      case "right":
        params.x = INTENSITY.xy[options.intensity];
        break;

      case "bottom":
        params.y = INTENSITY.xy[options.intensity];
        break;

      case "left":
        params.x = -INTENSITY.xy[options.intensity];
        break;

      case "alternate-x":
        params.x = gsap.utils.wrap([
          INTENSITY.xy[options.intensity],
          -INTENSITY.xy[options.intensity],
        ]);
        break;
    }

    requestAnimationFrame(() => {
      gsap.from(options.useChildren ? target.children : target, {
        scrollTrigger: {
          trigger,
          start: "top bottom",
          toggleActions: "restart none none reset",
          onEnter: options.onEnter,
          onLeave: options.onLeave,
          onEnterBack: options.onEnterBack,
          onLeaveBack: options.onLeaveBack,
          onToggle: options.onToggle,
          onUpdate: options.onUpdate,
          onRefresh: options.onRefresh,
        },
        autoAlpha: options.fade ? 0 : 1,
        force3D: "auto",
        stagger: STAGGER[options.stagger],
        duration: DURATION[options.duration],
        ease: "circ.out",
        ...params,
      });
    });
  };

  const unveilText = (target, trigger, origin, options) => {
    const INTENSITY = { strong: 200, normal: 66, weak: 33 };
    const DURATION = { long: 1.2, normal: 0.75, quick: 0.3 };
    const STAGGER = {
      wide: 0.1,
      normal: 0.05,
      short: 0.0025,
      none: 0,
    };

    if (!options.intensity) options.intensity = "normal";
    if (!options.fade) options.fade = false;
    if (!options.duration) options.duration = "normal";
    if (!options.stagger) options.stagger = "none";
    if (!options.textTarget) options.textTarget = "chars";
    if (!options.onEnter) options.onEnter = () => {};
    if (!options.onLeave) options.onLeave = () => {};
    if (!options.onEnterBack) options.onEnterBack = () => {};
    if (!options.onLeaveBack) options.onLeaveBack = () => {};
    if (!options.onToggle) options.onToggle = () => {};
    if (!options.onUpdate) options.onUpdate = () => {};
    if (!options.onRefresh) options.onRefresh = () => {};

    let type = "";

    switch (options.textTarget) {
      case "chars":
        type = "chars, words, lines";
        break;

      case "words":
        type = "words, lines";
        break;

      case "lines":
        type = "lines";
        break;
    }

    let yPercent = 0;
    let xPercent = 0;
    let scale = 1;

    switch (origin) {
      case "top":
        yPercent = -INTENSITY[options.intensity];
        break;

      case "zoom-in":
        scale = INTENSITY[options.intensity];
        break;

      case "zoom-out":
        scale = -INTENSITY[options.intensity];
        break;

      case "right":
        xPercent = INTENSITY[options.intensity];
        break;

      case "bottom":
        yPercent = INTENSITY[options.intensity];
        break;

      case "left":
        xPercent = -INTENSITY[options.intensity];
        break;
    }

    let anim;
    let splitted = new SplitText(target);

    gsap.set(target, { visibility: "hidden" }); // do not remove (avoid initial flash)

    debouncedWatch(
      width,
      () => {
        if (anim) {
          anim.kill();
          anim = null;
          gsap.set(splitted[options.textTarget], { clearProps: "all" });
        }

        textSplitter(splitted, type);
        doUnveil();
      },
      { debounce: 250 }
    );

    function doUnveil() {
      requestAnimationFrame(() => {
        gsap.set(target, { lineHeight: 1 });
        anim = gsap.from(splitted[options.textTarget], {
          scrollTrigger: {
            trigger,
            start: "top bottom",
            toggleActions: "restart none none reset",
            onEnter: options.onEnter,
            onLeave: options.onLeave,
            onEnterBack: options.onEnterBack,
            onLeaveBack: options.onLeaveBack,
            onToggle: options.onToggle,
            onUpdate: options.onUpdate,
            onRefresh: options.onRefresh,
          },
          onStart: () => {
            gsap.set(target, { visibility: "visible" }); // do not remove (solve initial flash)
          },
          xPercent,
          yPercent,
          scale,
          force3D: "auto",
          stagger: STAGGER[options.stagger],
          duration: DURATION[options.duration],
          ease: "circ.out",
        });
      });
    }

    textSplitter(splitted, type);
    doUnveil();
  };

  return {
    reveal,
    parallax,
    unveilText,
  };
}
