import { computed, onMounted } from "vue";
import { templateRef } from "@vueuse/core";
import { useScrollTriggerAnimation } from "/composables/useScrollTriggerAnimation";
import { useImageSources } from "/composables/useImageSources";
import useTailwindCss from "/composables/useTailwindCss";


export default {
  __name: 'index',
  props: {
  sizes: {
    type: Object,
    required: false,
  },
  aspectRatio: {
    type: [String, Object],
    required: false,
    default: "none",
  },
  loading: {
    type: String,
    required: false,
    validator: function (value) {
      return ["auto", "eager", "lazy"].indexOf(value) !== -1;
    },
    default: "auto",
  },
  media: {
    type: Object,
    required: true,
  },
  width: {
    type: [String, Number],
    required: false,
  },
  height: {
    type: [String, Number],
    required: false,
  },
  bg: {
    type: String,
    required: false,
    default: "bg-skin-surface",
  },
  wrapperClass: {
    type: String,
    required: false,
  },
  objectFit: {
    type: String,
    required: false,
    validator: function (value) {
      return ["cover", "contain"].indexOf(value) !== -1;
    },
    default: "cover",
  },
  objectPosition: {
    type: String,
    required: false,
    validator: function (value) {
      return (
        [
          "top",
          "right-top",
          "right",
          "right-bottom",
          "bottom",
          "left-bottom",
          "left",
          "left-top",
          "center",
        ].indexOf(value) !== -1
      );
    },
    default: "center",
  },
  scrollTrigger: {
    type: String,
    required: false,
    validator: function (value) {
      return ["parallax", "fade", "none"].indexOf(value) !== -1;
    },
    default: "none",
  },
},
  setup(__props) {

const props = __props



const tpl_root = templateRef("tpl_root");
const tpl_wrapper = templateRef("tpl_wrapper");
const { parallax, reveal } = useScrollTriggerAnimation();
const { screens } = useTailwindCss();
const { getMedia } = useImageSources();

const computedInlineSize = computed(() => {
  let size = { width: props.width, height: props.height };

  if (
    !size.width &&
    !size.height &&
    props.aspectRatio &&
    props.aspectRatio != "none" &&
    !(
      typeof props.aspectRatio === "object" && props.aspectRatio.base === "none"
    )
  ) {
    let ar =
      typeof props.aspectRatio === "object"
        ? props.aspectRatio.base
        : props.aspectRatio;

    switch (ar) {
      case "1 / 1":
        size.width = 1000;
        size.height = 1000;
        break;

      case "3 / 2":
        size.width = 1000;
        size.height = 666;
        break;

      case "4 / 5":
        size.width = 1000;
        size.height = 1250;
        break;

      case "16 / 9":
        size.width = 1000;
        size.height = 562;
        break;

      case "16 / 7":
        size.width = 1000;
        size.height = 437;
        break;
    }
  }

  return size;
});

const attrs = computed(() => {
  let value = {
    is: null,
    src: null,
    class: null,
    imgClass: null,
    poster: null,
    alt: null,
    title: null,
    aspectRatio: [],
  };

  let fitting;

  switch (props.objectFit) {
    case "contain":
      fitting = "object-contain";
      break;

    case "cover":
    default:
      fitting = "object-cover";
      break;
  }

  fitting += " ";

  switch (props.objectPosition) {
    case "top":
      fitting += "object-top";
      break;

    case "right-top":
      fitting += "object-right-top";
      break;

    case "right":
      fitting += "object-right";
      break;

    case "right-bottom":
      fitting += "object-right-bottom";
      break;

    case "bottom":
      fitting += "object-bottom";
      break;

    case "left-bottom":
      fitting += "object-left-bottom";
      break;

    case "left":
      fitting += "object-left";
      break;

    case "left-top":
      fitting += "object-left-top";
      break;

    case "center":
    default:
      fitting += "object-center";
      break;
  }

  switch (props.media.type) {
    case "image":
      value.is = "pic";
      value.imgClass = `!w-full !h-full ${fitting} ${props.bg}`;
      value.alt = props.media.title;
      value.loading = props.loading;
      break;

    case "video":
      value.is = "video-bg";
      // value.poster = props.media.poster;
      value.title = props.media.title;
      value.class = `${fitting} ${props.bg}`;
      if (props.loading === "lazy") value.preload = "none";
      else if (props.loading === "eager") value.preload = "metadata";
      else value.preload = "auto";
      break;
  }

  if (props.media.type === "video" && !props.media.poster.match("http")) {
    value.poster = getMedia(props.media).poster;
  }

  value.src = props.sizes
    ? getMedia(props.media, props.sizes).src
    : props.media.src;

  if (props.aspectRatio) {
    if (typeof props.aspectRatio === "string") {
      switch (props.aspectRatio) {
        case "1 / 1":
          value.aspectRatio["aspect-1/1"] = true;
          break;

        case "4 / 5":
          value.aspectRatio["aspect-4/5"] = true;
          break;

        case "3 / 2":
          value.aspectRatio["aspect-3/2"] = true;
          break;

        case "16 / 9":
          value.aspectRatio["aspect-16/9"] = true;
          break;

        case "16 / 7":
          value.aspectRatio["aspect-16/7"] = true;
          break;

        case "none":
        default:
          value.aspectRatio["aspect-auto"] = true;
          break;
      }
    } else if (typeof props.aspectRatio === "object") {
      let ar;
      let mq = ``;
      let aspectWValue;
      let aspectHValue;
      let validScreenKeys = ["base"];

      // creates valid keys
      for (let key in screens) {
        if (key !== "base") validScreenKeys.push(key);
      }

      // loop over values
      for (let key in props.aspectRatio) {
        if (validScreenKeys.includes(key)) {
          // creates media query string
          mq = key === "base" ? `` : `${key}:`;

          if (props.aspectRatio[key] === "none") {
            value.aspectRatio[`${mq}aspect-auto`] = true;
          } else {
            ar = props.aspectRatio[key].replace(" / ", "/");

            aspectWValue = ar.substring(0, ar.indexOf("/")).replace(" ", "");
            aspectHValue = ar.substring(ar.indexOf("/") + 1).replace(" ", "");

            value.aspectRatio[
              `${mq}aspect-${aspectWValue}/${aspectHValue}`
            ] = true;
          }
        }
      }
    }
  }

  return value;
});

onMounted(() => {
  switch (props.scrollTrigger) {
    case "parallax":
      parallax(tpl_wrapper.value, tpl_root.value, { intensity: "normal" });
      break;

    case "fade":
      reveal(tpl_wrapper.value, tpl_root.value, "bottom", {
        fade: true,
        intensity: "strong",
      });
      break;
  }
});

return { __sfc: true,props, tpl_root, tpl_wrapper, parallax, reveal, screens, getMedia, computedInlineSize, attrs }
}

}